// This is the scss entry file
import "../styles/index.scss";
import 'choices.js/public/assets/styles/choices.min.css';

// This is the scss entry file
import Alpine from "alpinejs";
import Clipboard from "@ryangjchandler/alpine-clipboard";
import "flowbite";
import Choices from "choices.js";

Alpine.plugin(Clipboard);
window.Alpine = Alpine;


Alpine.start();



document.addEventListener('DOMContentLoaded', function () {
      const searchElements = document.querySelectorAll('.js-choice');
      if (searchElements) {
          searchElements.forEach(element => {
              new Choices(element, {
                  searchEnabled: true,
                  placeholderValue: 'Select an option...',
                  shouldSort: false,
                  itemSelectText: '',
                  searchResultLimit: 10,
                  position: 'bottom' // or 'top', 'bottom' based on your needs
              });
          });
      }
    });

